<template>
  <div class="product_box cart_product_box flex-r-c-c">
    <div class="pro_info flex-r-s-c">
      <van-image width="2.56rem" height="1.92rem" :src="myinfo.proIconUrl"
                 @click="(e) => { $router.push('/product/detail?productId=' + myinfo.productId) }"
      />
      <div v-if="myinfo.releaseFlag === 2" class="pro_sale_notWork"
           @click="(e) => { $router.push('/product/detail?productId=' + myinfo.productId) }"
      >
        <van-image width="1.39rem" height="1.39rem" src="https://oss.xiaoyi120.com/shop2.0/cart/cart_notwork.png" />
      </div>
      <div class="cart_pro_info" :style="{ opacity: myinfo.releaseFlag === 2? '0.3' :'1' }">
        <div class="pro_name van-multi-ellipsis--l2"
             @click="(e) => { $router.push('/product/detail?productId=' + myinfo.productId) }"
        >
          <van-image v-if="myinfo.releaseFlag === 3" width="0.75rem" height="0.43rem"
                     src="https://oss.xiaoyi120.com/shop2.0/product/presell_tag.png"
          />
          {{ myinfo.proName }}
        </div>
        <div v-if="myinfo.firstLevelTagId" class="pro_brand_model_spu flex-r-s-c" @click="openCart">
          <div class="van-ellipsis spu_brand" style="padding-left: 0.2rem;">{{ myinfo.firstLevelTagName }} |
            {{ myinfo.secondLevelTagName }}</div>
          <div class="spu_arrow flex-r-c-c">
            <van-icon name="arrow-down" />
          </div>
        </div>
        <div v-else class="pro_brand_model  van-ellipsis" style="width: 5.28rem;">{{ myinfo.proBrand }} |
          {{ myinfo.proModel }}</div>
        <div class="pro_tags_shop">
          <div v-for="(activity,activity_index) in myinfo.activityViewList" :key="activity_index"
               class="pro_tags_item flex-r-s-c"
          >
            <template v-if="activity.activityViewType === 1">
              <div class="tags_bill_item_left">￥</div>
              <div class="tags_bill_item_right">支持分期</div>
            </template>
            <!-- <template v-if="activity.activityViewType === 5">
              <div class="tags_return_item_left">返</div>
              <div class="tags_return_item_right van-ellipsis">{{ activity.activityViewName }}</div>
            </template> -->
          </div>
        </div>
        <div v-if="myinfo.subtractFlag === 1" class="downprice_cart flex-r-s-c">
          <div class="downprice_image flex-r-c-c">
            <van-image width="0.43rem" height="0.32rem"
                       src="https://hxxxkj-oss-cdn.xiaoyi120.com/sysx-xcx/mall/downPrice.png"
            />
          </div>
          <div class="price">近期降价￥{{ myinfo.subtractPrice }}</div>
        </div>
        <div class="pro_bottom flex-r-sb-c">
          <div class="pro_money">￥<span
            style="font-size: 0.49rem;"
          >{{ myinfo.showPrice === 0? myinfo.proPrice :'详询客服' }}</span></div>
          <div class="pro_num_change">
            <van-stepper v-model="myinfo.proNumber" :step="myinfo.multiplyQuantity === 0 ? 1:myinfo.minQuantity "
                         max="9999" :min="myinfo.minQuantity" integer :disabled="myinfo.releaseFlag === 2"
                         input-width="1rem" button-size="0.5rem" @change="changeNum" @blur="blurNum"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { shoppingCart_update } from '@/api/cart'
export default {
  props: {
    info: { type: Object, default: () => { } },
    cannum: { type: Number, default: null }
  },
  data() {
    return {
      myinfo: this.info,
      mycannum: this.cannum, copyNum: ''
    }
  },
  methods: {
    // 修改数量
    changeNum(val) {
      var limitNum = 0
      var orderLimit = parseInt(this.info.orderLimit)
      var userBuy = parseInt(this.info.userLimit) - parseInt(this.info.periodBuyTotal)
      if (orderLimit > 0 && orderLimit > userBuy) {
        if (limitNum > 0) { limitNum = userBuy }
      } else {
        limitNum = orderLimit
      }
      if (orderLimit === userBuy) { limitNum = orderLimit }
      if (val) {
        const parms = {
          proNumber: val, productId: this.info.productId,
          shoppingCartId: this.info.shoppingCartId
        }
        console.log(parms)
        shoppingCart_update(parms).then(res => {
          this.$emit('changeNum', res.data)
        }).catch(() => {
          if (this.mycannum) { this.myinfo.proNumber = this.mycannum } else { this.myinfo.proNumber = limitNum > 0 ? limitNum : this.myinfo.minQuantity }
        })
      }
    },
    // 失焦事件
    blurNum(e) {
      if (!e.currentTarget.value) {
        this.myinfo.proNumber = 1
      }
    },
    openCart() {
      this.$emit('openCart', this.myinfo)
    },
    updateMessage(newMessage) {
      this.myinfo = newMessage
    }
  }
}
</script>
